const mutations = {
    login(state, data) {
        state.token = data.token

        state.renderedScreenAnimation = false
        if(data.initialView !== null) {
            state.currentViewContent = JSON.parse(data.initialView.content)
            state.currentViewFiles = data.initialView.file
            state.currentViewType = data.initialView.view_type_id
            state.currentViewTypeTimestamp = data.initialView.updated_at
            state.renderedScreenAnimation = true
        } else {
            state.currentViewType = 0
            state.renderedScreenAnimation = true
        }
        state.color = data.color
        state.customerId = data.customer_id
        state.offlineCounter = 0
        state.logoUrl = data.logo_url
        localStorage.setItem('color', data.color)
        localStorage.setItem('customer_id', data.customer_id)
        localStorage.setItem('logo_url', data.logo_url)
        localStorage.setItem('deviceId', data.deviceId)
        localStorage.setItem('token', data.token)
    },

    previewLogin(state, data) {
        state.token  = data.token
        state.currentViewType = data.item.view_type.id
        state.currentViewContent = JSON.parse(data.item.content)
    },

    logout(state) {
        state.token = null
        localStorage.removeItem('token')
    },

    updateCreationView(state, data) {
        state.currentViewType = data.item.view_type.id
        state.currentViewContent = JSON.parse(data.item.content)
    },

    setView(state, data) {
        state.renderedScreenAnimation = false
        let previousId = state.currentViewType
        let transferredId = data.view ? data.view.view_type_id : data.view
        setTimeout(() => {
            if(data.view) {
                state.currentViewContent = JSON.parse(data.view.content)
                state.currentViewFiles = data.view.file
                state.currentViewType = data.view.view_type_id
                state.currentViewId = data.view.id
                state.currentViewTypeTimestamp = data.view.updated_at
                state.renderedScreenAnimation = true
            } else {
                state.currentViewContent = null
                state.currentViewFiles = null
                state.currentViewType = 0
                state.currentViewId = null
                state.currentViewTypeTimestamp = null
                state.renderedScreenAnimation = true
            }
        }, 500)
        if(previousId === transferredId && previousId === 2) {
            state.resetNewsScroll = true
        }
    },
    setOfflineCounter(state, data) {
        if(data === 1) {
            state.offlineCounter = state.offlineCounter + 1
        } else if (data === 0) {
            state.offlineCounter = 0
        }
    },
    setResetNewsScroll(state, data) {
        state.resetNewsScroll = data
    },
    setReloadWebview(state, data) {
        localStorage.setItem('reloadWebview', data)
        state.reloadWebview = data
    },
    setCurrentLogo(state, data) {
        state.logoUrl = data
        state.currentLogoTimestamp = new Date().toISOString()
    },
    setPreviewData(state, data) {
        if(data.view) {
            state.currentViewContent = JSON.parse(data.view.content)
            state.currentViewFiles = data.view.file
            state.currentViewType = data.view.view_type_id
            state.currentViewId = data.view.id
            state.currentViewTypeTimestamp = data.view.updated_at
            state.renderedScreenAnimation = true
        } else {
            state.currentViewContent = null
            state.currentViewFiles = null
            state.currentViewType = 0
            state.currentViewId = null
            state.currentViewTypeTimestamp = null
            state.renderedScreenAnimation = true
        }
        if(data.customer) {
            state.color = data.customer.color
            state.customerId = data.customer.id

            if(data.customer.image) {
                state.logoUrl = data.customer.image.url ? data.customer.image.url : null
            }
        }
    }
}

export default mutations
