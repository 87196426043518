import Notice from "@/views/notice/Notice";
import Welcome from "@/views/welcome/Welcome";
import News from "@/views/news/News";
import SimpleLogo from "@/views/simpleLogo/SimpleLogo";
import Link from "@/views/link/Link";
import Gallery from "@/views/gallery/Gallery";
import RoomSign from "@/views/roomSign/RoomSign";
import BulletinBoard from "@/views/bulletinBoard/BulletinBoard";
import ImageSlider from "@/views/imageSlider/ImageSlider.vue";
const getters = {
    getVersionNumber: state => {
        return state.versionNumber
    },
    getRenderedScreen: state => {
        return state.renderedScreen
    },

    getRenderedScreenAnimation: state => {
        return state.renderedScreenAnimation
    },

    getCurrentViewTypeComponent: state => {
        switch (state.currentViewType) {
            case 1:
                return Welcome
            case 2:
                return News
            case 3:
                return Notice
            case 4:
                return Link
            case 5:
                return Gallery
            case 6:
                return RoomSign
            case 7:
                return BulletinBoard
            case 8:
                return ImageSlider
            case 0:
                return SimpleLogo
        }
    },

    getCurrentViewId: state => {
        return state.currentViewId
    },

    getCurrentViewType: state => {
        return state.currentViewType
    },

    getCurrentViewTypeTimestamp: state => {
        return state.currentViewTypeTimestamp
    },

    getCurrentViewContent: state => {
        return state.currentViewContent
    },

    getCurrentViewFiles: state => {
        return state.currentViewFiles
    },

    getCustomerIdentifier: state => {
        return state.customerIdentifier
    },

    getCustomerColor: state => {
        return state.color
    },
    getCustomerId:state => {
        return state.customerId
    },
    getResourcePath:state => {
        return state.resourcePath
    },
    getOfflineCounter:state => {
        return state.offlineCounter
    },
    getResetNewsScroll:state => {
        return state.resetNewsScroll
    },
    getAppVersionNumber: state => {
        return state.appVersionNumber
    },
    getReloadWebview: state => {
        return state.reloadWebview
    },
    getCurrentLogoTimestamp: state => {
        return state.currentLogoTimestamp
    },
    getLogoUrl:state => {
        return state.logoUrl
    }
}

export default getters
