<template>
<div class="bulletin-board_wrapper">
  <div>
    <div class="header-bar">
      <div class="icon"></div>
      <div class="logo"></div>
    </div>
  </div>
  <div class="bulletin-main">
    <div class="padding-frame">
      <div class="column-wrapper flexbox">
        <div class="column-1">
          <h2>Neuigkeiten rundum FGB</h2>
<!--          <div style="width: 100%; margin-bottom: 20px; max-height: 30%;" v-if="getCurrentViewFiles && getCurrentViewFiles.length > 0 && getCurrentViewFiles[0].url">-->
<!--            <v-img :src="getCurrentViewFiles[0].url" contain max-height="100%" style="width: 100%;"/>-->
<!--&lt;!&ndash;            <v-img :src="getCurrentViewFiles[0].url" style="width: 100%;"/>&ndash;&gt;-->
<!--          </div>-->
<!--          <v-img :src="getCurrentViewFiles[0].url" max-height="30%" style="width: 100%; margin-bottom:20px;" v-if="getCurrentViewFiles && getCurrentViewFiles.length > 0 && getCurrentViewFiles[0].url" />-->
          <v-img :src="getCurrentViewFiles[0].url" contain max-height="30%" style="width: 100%; margin-bottom:20px;" v-if="getCurrentViewFiles && getCurrentViewFiles.length > 0 && getCurrentViewFiles[0].url" />
          <p class="date"><strong>{{ new Date(getCurrentViewContent.date_news).toLocaleDateString("de-DE", {day: "2-digit", month: "long", year: "numeric"}) }}</strong></p>

          <h4>{{ getCurrentViewContent.headline_news }}</h4>

          <div class="text_news" v-html="getCurrentViewContent.text_news">
          </div>
        </div>
        <div class="column-2">
          <div class="date-information">
            <h2>Termininformationen</h2>
            <div v-html="getCurrentViewContent.welcome_text"/>
          </div>
          <div class="weather-information">
            <h2>Wetterinformationen</h2>
            <div class="frame-wrapper flexbox">
              <iframe src="https://www.meteoblue.com/de/wetter/widget/three/bad-neustadt-an-der-saale_deutschland_2953389?geoloc=fixed&nocurrent=0&noforecast=0&days=5&tempunit=CELSIUS&windunit=KILOMETER_PER_HOUR&layout=image"  frameborder="0" scrolling="NO" allowtransparency="true" sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
                      style="height: 596px; width: 600px; max-height: 596px; max-width: 600px;"></iframe>
            </div>
          </div>
        </div>
        <div class="column-3">
          <div class="newest-member">
            <h2>Herzlich Willkommen</h2>
            <div class="flexbox" style="height: 237px">
              <div class="icon"></div>
              <div class="text" v-html="getCurrentViewContent.date_info_text">
              </div>
            </div>
          </div>
          <div class="gallery">
            <h2>Fotogalerie</h2>
            <div class="frame-wrapper flexbox">
              <iframe src="https://www.fgb.de/viewneo/bb_gallery/bb_gallery.php" style="height: 560px; width: 685px; max-height: 560px; max-width: 685px;"></iframe>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="newsticker">
    <iframe src="https://www.fgb.de/viewneo/lauftext/spiegel_feed.php" frameborder="0"></iframe>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BulletinBoard",
  computed: {
    ...mapGetters({
      getCurrentViewContent: 'getCurrentViewContent',
        getCurrentViewFiles: 'getCurrentViewFiles'
    })
  }
}
</script>

<style scoped>
.bulletin-board_wrapper{
  margin: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.5em;
  font-family: 'Roboto', sans-serif;
  width: 1920px;
  max-width: 1920px;
  height: 1080px;
  max-height: 1080px;
}
h2{
  font-size: 32px;
  font-family: 'Roboto-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h4{
  margin: 0 0 40px 0;
}
ul{
  padding: 0;
}
li{
  list-style-type: none;
  padding: 0 0 20px 0;
}
strong{
  font-family: 'Roboto-bold', sans-serif;
}

.flexbox{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}
.padding-frame{
  padding: 0 40px;
}
.header-bar .icon{
  background: url("./group-logo.jpeg") no-repeat;
  height: 50px;
  width: 50px;
  background-size: cover;
  float: left;
}

.header-bar{
  height: 100px;
  align-items: center;
  justify-content: flex-end;
}
.header-bar > .logo{
  float: right;
  line-height: 100px;
  background: url("./logo.png");
  height: 50px;
  width: 110px;
  margin: 25px 40px 25px 0;
}

.column-wrapper{
  width: 100%;
}
.column-wrapper > div{
  overflow: hidden;
}
.column-1{
  min-width: 485px;
  max-width: 485px;
  width: calc(30% - 40px);
  margin: 20px 40px 10px 0;
  max-height: 890px;
}
.column-2{
  width: calc(33% - 40px);
  margin: 20px 40px 10px 0;
  min-width: 600px;
  max-width: 600px;
  max-height: 890px;
}
.column-3{
  width: 37%;
  margin: 20px 0 10px 0;
  min-width: 675px;
  max-width: 675px;
  max-height: 890px;
}
.column-2 .date-information{
  max-height: 237px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 3px solid #EEE;
  display: block;
}
.column-2 .date-information div {
  max-height: 170px;
  min-height: 170px;
  overflow: hidden;
}
.column-2 .date-information ul li{
  overflow: hidden;
  -webkit-box-orient: vertical;
  height: 20px;
  padding: 0;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
}
.column-2 .weather-information{
  height: 65%;
  width: 100%;
}
.column-2 .weather-information iframe{
  margin-top: 10px;
  width: 100%;
  border: none;
  height: 542px;
}
.column-3 .newest-member{
  max-height: 237px;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 3px solid #EEE;
}
.column-3 .newest-member .icon{
  background: url("./icon-m.png") no-repeat center 4px;
  max-height: 160px;
  width: 80px;
  min-width: 80px;
  float: left;
  margin-right: 25px;
}
.column-3 .newest-member .text{
  float: right;
  max-height: 165px;
  overflow: hidden;
}

.column-3 .gallery iframe{
  border: none;
  height: 560px;
  min-width: 685px;
  max-width: 685px;
}
.gallery h2{
  margin-bottom: 0;
}

.frame-wrapper{
  justify-content: center;
}

.newsticker{
  background-color: #4995D1;
  height: 60px;
}
.newsticker iframe{
  background-color: #4995D1;
  opacity: 1;
  width: 100%;
  height: 60px;
}

.padding-frame{
  background-image: url("./background.jpeg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
